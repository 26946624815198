var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{ref:_vm.day.value + '-reference',staticClass:"kalendar-day",class:{
    'is-weekend': _vm.isWeekend,
    'is-today': _vm.isToday,
    creating: _vm.creator.creating || _vm.creator.status === 'popup-initiated'
  },staticStyle:{"position":"relative"}},[(_vm.isToday && _vm.passedTime)?_c('div',{ref:"nowIndicator",class:_vm.kalendar_options.style === 'material_design'
        ? 'hour-indicator-line'
        : 'hour-indicator-tooltip',style:(`top:${_vm.passedTime}px`)},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.kalendar_options.style === 'material_design'),expression:"kalendar_options.style === 'material_design'"}],staticClass:"line"})]):_vm._e(),_vm._l((_vm.day_cells),function(cell,index){return _c('kalendar-cell',{key:`cell-${index}`,attrs:{"constructed-events":_vm.day_events,"creator":_vm.creator,"cell-data":cell,"index":index,"temporary-event":_vm.temporary_event},on:{"select":_vm.updateCreator,"reset":function($event){return _vm.resetEvents()},"initiatePopup":function($event){return _vm.initiatePopup()}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }